// i18next-extract-mark-ns-start capabilities-electrification-hybrid

import { LINKS, ELECTRIFICATION_NAV, CAPABILITIES_PRODUCTS_ELECTRIFICATION_HEV_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import ResourceListing from '@components/views/ResourceListing';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesHybridSystemsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);
	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const mech = getImage(data.mech.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);


	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Rapid hybrid system component performance simulation</h2>

						<p>
							Component sizing has major effects on buying and owning costs, fuel/energy consumption, maintenance and vehicle performance
							including acceleration and maximum speed. The simulation software provides a multi-criteria decision tool using 1D modelling
							for vehicle level design and detailed engine performance governing emissions, noise, and economy.
						</p>
						<ul>
							<li>Allows investigation of hybrid systems without specific battery, motor and component experience</li>
							<li>Building blocks including driver inputs, transmission, batteries, motors, engine parameters and controls </li>
							<li>Simulates the balance of vehicle performance with fuel economy and lower emissions</li>
							<li>Fast model setup and rapid simulation times reducing the need for physical prototype builds</li>
						</ul>
						<p>
							Hybrid system analysis consists of four packages that calculate combustion, temperatures, mechanical losses, and emissions data used in the system model.
						</p>
					</Trans>

					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							IGNITE is a complete physics-based hybrid simulation package. With a library system of building blocks,
							the user can quickly and accurately model complete hybrid systems, analysing electric motor control and engine performance.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_3">
						<p>
							WAVE is a 1D Computational Fluid Dynamics (CFD) tool that provides Internal Combustion Engine (ICE) data to the system model across a range of drive cycle scenarios.
						</p>
					</Trans>

					{mech && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={mech}
								alt="Structual Mechanics"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_4">
						<p>
							The structural mechanics suite, including SABR, ENGDYN and VALDYN,
							predict transmission and mechanical engine losses as inputs to the drive cycle system model.
						</p>
					</Trans>

					{
						vectis && (
							<div className="product-lead-icon image-contain">
								<GatsbyImage
									image={vectis}
									alt="VECTIS"
									className="icon-image"
								/>
							</div>
						)
					}
					<Trans i18nKey="content_5">
						<p>
							Separately, VECTIS, is a 3D CFD simulation tool that predicts battery thermal behaviour
							enabling a detailed model of battery performance.
						</p>
					</Trans>
				</div >
				<Aside>
					<Aside.Widget
						title={t("_TechnologyTransition")}
						className="widget__sibling__pages">
						<AssetList>
							{ELECTRIFICATION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_ELECTRIFICATION_HEV_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection >

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page >
	);
};

export default CapabilitiesHybridSystemsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-electrification-hybrid", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/electrification/Realis-Simulation_3000x1700_hybridVehiclesRange_flipped.jpg" }) {
		...imageBreaker
	}
	ignite: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
		...imageBreaker
	}
	wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
		...imageBreaker
	}
	mech: file(relativePath: { eq: "content/logos/StructuralMechanics_notxt.png" }) {
		...imageBreaker
	}
	vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { capabilities: { in: "Electrification" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
